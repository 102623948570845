<template>
    <div class="m-home-view">
        <div class="brand">MaxSaving</div>
        <div class="slogan">集合财务、业务、运维的三位一体云成本管理平台</div>
        <div class="entry-panel">
            <Button type="primary" @click="goBusiness">{{
                isLogin ? '立即使用' : '登录'
            }}</Button>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.m-home-view {
    position: relative;
    height: 100vh;
    background: linear-gradient(to bottom, @primary-color 40%, #fff);

    .brand {
        padding-top: 20vw;
        font-size: 16vw;
        font-weight: bold;
        line-height: 20vw;
        text-align: center;
        color: #fff;
    }

    .slogan {
        padding-top: 8vw;
        font-size: 3.4vw;
        color: #fff;
        text-align: center;
    }

    .entry-panel {
        position: absolute;
        width: 100%;
        height: 36vw;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.btn {
    display: block;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 2vw;
    text-align: center;
    border-radius: 1.5vw;
    color: #333;
    font-size: 3.4vw;

    &.primary {
        background-color: @primary-color;
        color: #fff;
        border: 1px solid @primary-color;
    }
}
</style>

<script setup>
import { computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import { Button, showToast } from 'vant';
import { useUserStore } from '@/stores/global';

const store = useUserStore();

const router = useRouter();

const route = useRoute();

let isLogin = computed(
    () => store.currentUser.username && store.currentUser.username !== 'guest01'
);

function goBusiness() {
    router.push(
        isLogin.value ? '/business/cost/_default/_default' : 'user/login'
    );
}

// function goGuide() {
//     window.open(
//         'https://confluence.mobvista.com/pages/viewpage.action?pageId=79362194'
//     );
// }

onMounted(() => {
    if (+route.query.login === 1) {
        showToast(
            `欢迎回来，${store.currentUser.username}，MaxSaving即将展现！`
        );
        setTimeout(() => {
            goBusiness();
        }, 1000);
    }
});
</script>
